import "./Faq.css";
import Accordion from "react-bootstrap/Accordion";

function Faq() {
  const faq = [
    {
      Q: "Comment fonctionne Planity pour mes clients ?",
      R: "Après la création de votre page salon personnalisée sur le portail de réservation , vos clients pourront réserver vos prestations en quelques clics, selon vos créneaux disponibles. Finies les interruptions téléphoniques, les RDV sont gérés en toute autonomie, toujours selon vos contraintes.",
    },
    {
      Q: "Comment fonctionne Planity pour mes clients ?",
      R: "Après la création de votre page salon personnalisée sur le portail de réservation , vos clients pourront réserver vos prestations en quelques clics, selon vos créneaux disponibles. Finies les interruptions téléphoniques, les RDV sont gérés en toute autonomie, toujours selon vos contraintes.",
    },
    {
      Q: "Comment fonctionne Planity pour mes clients ?",
      R: "Après la création de votre page salon personnalisée sur le portail de réservation , vos clients pourront réserver vos prestations en quelques clics, selon vos créneaux disponibles. Finies les interruptions téléphoniques, les RDV sont gérés en toute autonomie, toujours selon vos contraintes.",
    },
    {
      Q: "Comment fonctionne Planity pour mes clients ?",
      R: "Après la création de votre page salon personnalisée sur le portail de réservation , vos clients pourront réserver vos prestations en quelques clics, selon vos créneaux disponibles. Finies les interruptions téléphoniques, les RDV sont gérés en toute autonomie, toujours selon vos contraintes.",
    },
    {
      Q: "Comment fonctionne Planity pour mes clients ?",
      R: "Après la création de votre page salon personnalisée sur le portail de réservation , vos clients pourront réserver vos prestations en quelques clics, selon vos créneaux disponibles. Finies les interruptions téléphoniques, les RDV sont gérés en toute autonomie, toujours selon vos contraintes.",
    },
  ];
  return (
    <div className="container faq">
      <h2>Les questions fréquentes</h2>
      <Accordion>
        {faq.map((el, index) => {
          return (
            <Accordion.Item eventKey={index}>
              <Accordion.Header>{el.Q}</Accordion.Header>
              <Accordion.Body>{el.R}</Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
}

export default Faq;
