import React, { useState } from "react";
import axios from "axios";
import "./Hero.css";
import { Button, Form, InputGroup } from "react-bootstrap";
import france from "../../assets/images/france.svg";
import { MdDone } from "react-icons/md";

const Hero = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    postalCode: "",
    phoneNumber: "",
    specialty: "",
    email: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.firstName) tempErrors.firstName = "Prénom est requis";
    if (!formData.lastName) tempErrors.lastName = "Nom est requis";
    if (!formData.specialty) tempErrors.specialty = "Spécialité est requise";
    if (!formData.email) tempErrors.email = "Email est requis";
    return tempErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      axios
        .post("ht lapi dyalk hna ", formData)
        .then((response) => {
          console.log("Data submitted successfully:", response.data);
        })
        .catch((error) => {
          console.error("There was an error submitting the data:", error);
        });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="hero">
      <div className="container">
        <div className="row">
          <div className="col-md-6 first-section">
              {/* <div className="links-social">
                <a href="#d">contact@noubty.com</a>
                <a href="#ds">0000000000</a>
              </div> */}
            <div className="content-hero">
              <h1>Simple et Efficace</h1>
              <p>
              Simplifiez votre processus de réservation et limitez la file d'attente quand vous le souhaitez.
              </p>
              <div className="list">
                <div>
                  <MdDone />
                  <p>Après la soumission de votre formulaire, nous allons vous contacter pour répondre à toutes vos questions.</p>
                </div>
                <div>
                  <MdDone />
                  <p>Nous prendrons toutes les informations nécessaires pour créer un nouveau compte et vous donner accès.</p>
                </div>
                <div>
                  <MdDone />
                  <p>Nous sommes toujours disponibles pour vous assurer d'obtenir le meilleur service</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-modal">
              <h2>
                Vous êtes gérant d’une salle d'attente ? Un de nos experts
                vous recontacte
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="d-flex">
                  <div>
                    <label htmlFor="Prénom">Prénom*</label>
                    <input
                      name="firstName"
                      placeholder="Entrer votre prénom..."
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && (
                      <span className="error">{errors.firstName}</span>
                    )}
                  </div>
                  <div>
                    <label htmlFor="Nom">Nom*</label>
                    <input
                      name="lastName"
                      placeholder="Entrer votre nom..."
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName && (
                      <span className="error">{errors.lastName}</span>
                    )}
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                    <label htmlFor="postalCode">Code postal</label>
                    <input
                      name="postalCode"
                      placeholder="XXXXX"
                      value={formData.postalCode}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="phoneNumber">Téléphone portable</label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <img src={france} alt="" />
                      </InputGroup.Text>
                      <Form.Control
                        name="phoneNumber"
                        placeholder="Téléphone portable"
                        aria-label="Téléphone portable"
                        aria-describedby="basic-addon1"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </div>
                </div>
                <div>
                  <label htmlFor="specialty">Votre spécialité*</label>
                  <select
                    name="specialty"
                    value={formData.specialty}
                    onChange={handleChange}
                  >
                    <option value="">--selectionner--</option>
                    <option value="option1">Médecin</option>
                    <option value="option2">Coiffeur</option>
                    <option value="option3">Agence</option>
                    <option value="option4">Autre</option>
                  </select>
                  {errors.specialty && (
                    <span className="error">{errors.specialty}</span>
                  )}
                </div>
                <div>
                  <label htmlFor="email">Email*</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Entrer votre email ..."
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
                <Button type="submit">Submit</Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
