import React from "react";
import "./Growth.css";

const Growth = () => {
  return (
    <div className="growth">
      <div className="container">
        <div>
          <div className="growth_title">50%</div>
          <div className="growth_body">Identifier & Améliorer</div>
        </div>
        <div>
          <div className="growth_title">25%</div>
          <div className="growth_body">Surveiller & Optimiser</div>
        </div>
        <div>
          <div className="growth_title">25%</div>
          <div className="growth_body">Feedback & Analyse</div>
        </div>
      </div>
    </div>
  );
};

export default Growth;
