import './Header.css'
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function Header() {
  return (
    <Navbar expand="lg" className="header container">
      <Navbar.Brand href="#">LOGO</Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav className="me-auto my-2 my-lg-0 gap-4 links-principal" navbarScroll>
          <Nav.Link href="/service-detail/1">Votre métier</Nav.Link>
          <Nav.Link href="/reserve-detail/1">Témoignages</Nav.Link>
          <Nav.Link href="/reserve">Ressources</Nav.Link>
          {/*<Nav.Link href="#">Estimateur de rentabilité</Nav.Link>*/}
        </Nav>
        <div className="d-flex gap-3 align-items-center">
          {/*<Nav.Link href="#action1" className="connect">
            Se connecter
  </Nav.Link>*/}
          <Button className='btn-header' onClick={() => window.scrollTo({ left: 0, top: 100, behavior: "smooth" })} >Demander une démo</Button>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
