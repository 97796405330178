import React from 'react'
import './Service.css'
import Slide from '../../components/Slide/Slide';
import ListServices from '../ListServices/ListServices';
import { Link, useParams } from 'react-router-dom';

const Service = () => {
  const { id } = useParams();
  console.log(id);
  return (
    <>
    <Slide/>
      <div className="service">
        <div className="container">
          <div className="description">
            <p>
              Vous désirez une nouvelle coupe de cheveux pour sublimer votre
              visage ou une coiffure tendance pour sortir ? Que ce soit pour des
              cheveux longs, courts ou mi-longs, l’art du coiffage doit être
              laissé aux professionnels. Pour chaque objectif relooking, il y a
              une méthode précise que seuls les coiffeurs aguerris peuvent
              mettre en œuvre. Nous avons sélectionné pour vous des coiffeurs à
              Paris, à Bordeaux, à Toulouse ou encore à Lyon.
            </p>
            <article>
              <h2>
                Où trouver un salon de coiffure pour une technique particulière
                ?
              </h2>
              <section>
                <h3>Qu’en est-il de la coiffure pour homme ?</h3>
                <div>
                  <p>
                    Les petits salons de coiffure dédiés spécialement à la gent
                    masculine sont pris d’assaut. Savant mélange entre le
                    barbier et le salon de coiffure traditionnel, ces nouveaux
                    salons taillent la barbe, pratiquent le rasage à l’ancienne
                    et coupent les cheveux pour une allure stylée et moderne.
                    Notre sélection des meilleures adresses pour homme nous
                    conduit à Angel Studio dans le 17 ème , qui est un de nos
                    coups de cœur, car le salon offre une prestation complète et
                    reçoit autant les hommes, les femmes et les enfants. Vous
                    apprécierez son style décalé et son ambiance atypique pour
                    passer un agréable moment entre les mains expertes des
                    coiffeuses.
                  </p>
                </div>
                <img
                  src="https://res.cloudinary.com/planity/image/upload/f_auto/v1559728761/portail/jobs_hair_dresser.jpg"
                  alt=""
                />
              </section>
              <section>
                <h3>Qu’en est-il de la coiffure pour homme ?</h3>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Modi facilis labore blanditiis est ducimus? Saepe vero
                    doloribus illo dolore atque, dignissimos maxime eaque hic
                    minima expedita similique necessitatibus perferendis.
                    Cupiditate.
                  </p>
                </div>
              </section>
              <ListServices/>
              <section className="bottom-links">
                <h2>Recherches fréquentes</h2>
                <ul>
                  <li>
                    <Link to="/service-detail/1">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/2">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/3">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/4">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/5">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/6">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/7">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/8">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/9">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/10">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/11">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/12">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/13">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/14">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/15">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/16">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/17">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/18">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/19">Coiffure jeunes</Link>
                  </li>
                  <li>
                    <Link to="/service-detail/20">Coiffure jeunes</Link>
                  </li>
                </ul>
              </section>
            </article>
          </div>
        </div>
      </div>
    </>
  );
}

export default Service