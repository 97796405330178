import React from "react";
import "./BookingDetails.css";
import { MdOutlineLocationOn, MdOutlineStarOutline } from "react-icons/md";
import Slide from "../../components/Slide/Slide";

const BookingDetails = () => {
  const salon = {
    name: "Sauvageonne de Figure Libre",
    address: "25 Rue Camille Sauvageau, 33800 Bordeaux",
    rating: 5.0,
    reviews: 1008,
    price: "€€",
    availability: "Jeudi 6 Juin 2024",
    image:
      "https://res.cloudinary.com/planity/image/upload/t_d_search,f_auto/uhmmqccxdilgcupljse3",
  };
  const data = [0, 1, 2, 3, 4];
  return (
    <div className="booking">
      <div className="container">
        <div className="salon-details">
          <h3>{salon.name}</h3>
          <p>
            <MdOutlineLocationOn />
            {salon.address}
          </p>
          <p>
            <MdOutlineStarOutline />
            {salon.rating} ({salon.reviews} avis) • {salon.price}
          </p>
        </div>
        <Slide />
        <div className="booking-detail">
          <h3>Réserver en ligne pour un RDV chez Holy Cut</h3>
          <p>24h/24 - Paiement sur place - Confirmation immédiate</p>
          <div className="prix-and-date">
            <div className="prix">
              <h3>Choix de la prestation</h3>
              <h4>COUPES COURTES</h4>
              <p>
                Les coupes comprennent un diagnostic personnalisé, un shampooing
                adapté et le coiffage.
              </p>
              <div>
                {data.map(() => {
                  return (
                    <div className="service-card">
                      <div>
                        <p>Dégradé</p>
                        <span>Lorem ipsum dolor sit amet.</span>
                      </div>
                      <span>30min - 30$</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="date">
              <h4>Horaires d'ouverture</h4>
              <div className="date-detail">
                <div className="card-date">
                  <p>lundi</p>
                  <span className="ferme">Fermé</span>
                </div>
                <div className="card-date">
                  <p>lundi</p>
                  <span>09:00 - 17:00</span>
                </div>
                <div className="card-date">
                  <p>lundi</p>
                  <span>09:00 - 17:00</span>
                </div>
                <div className="card-date">
                  <p>lundi</p>
                  <span>09:00 - 17:00</span>
                </div>
                <div className="card-date">
                  <p>lundi</p>
                  <span>09:00 - 17:00</span>
                </div>
                <div className="card-date">
                  <p>lundi</p>
                  <span className="ferme">Fermé</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
