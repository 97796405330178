import React from 'react';
import './Advantages.css'
import { MdDone } from "react-icons/md";
import image from '../../assets/images/waiting-room.jpeg'



function Advantages() {
  return (
    <div className="advantages">
      <div className="container">
        <h2>+50% de rendez-vous sauvés</h2>
        <div className="row">
          <div className="col-md-6">
            <img src={image} alt="img" />
          </div>
          <div className="col-md-6">
            <h3>Des clients qui viennent au rendez-vous.</h3>
            <p>
            Noobti est la solution idéale pour les files d'attente non mesurables. Elle est conçue autour de trois processus principaux : la réservation à domicile, la réservation sur place et la réservation en cas d'urgence. Pour ces trois cas d'usage, le gestionnaire a le contrôle total de la file.
            </p>
            <div className="list">
              <p>
                <MdDone />
                Les professionnels peuvent ajouter, supprimer ou transférer des rendez-vous en toute simplicité.
                Ils ont également la possibilité de lancer, d'arrêter ou de réinitialiser la file d'attente à tout moment.
              </p>
              <p>
                <MdDone />
                Les clients peuvent facilement créer ou annuler des rendez-vous en un seul clic.
              </p>
              <p>
                <MdDone />
                Pas d'horaire fixe ni de nombre fixe de clients.
                Tous vos clients sont organisés dans une liste numérique centralisée pour augmentez la capacité de la liste d'attente et réduisez le temps d'attente
              </p>
            </div>
            <button onClick={() => window.scrollTo({ left: 0, top: 100, behavior: "smooth" })} >Découvrir gratuitement</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advantages;