import React from 'react'
import Header from '../../components/Header/Header'
import Growth from '../../components/Growth/Growth'
import Hero from '../../components/Hero/Hero'
import Advantages from '../../components/Advantages/Advantages'
import Faq from '../../components/Faq/Faq'

const Home = () => {
  return (
    <>
      <Hero />
      <Growth />
      <Advantages/>
      <Faq/>
    </>
  );
}

export default Home