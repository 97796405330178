// src/data.js
export const salons = [
  {
    id: 1,
    name: "Sauvageonne de Figure Libre",
    address: "25 Rue Camille Sauvageau, 33800 Bordeaux",
    rating: 5.0,
    reviews: 1008,
    price: "€€",
    availability: "Jeudi 6 Juin 2024",
    image:
      "https://res.cloudinary.com/planity/image/upload/t_d_search,f_auto/uhmmqccxdilgcupljse3",
  },
  {
    id: 2,
    name: "Studio H - Saint-Paul",
    address: "3 Rue des Boucheries, 33000 Bordeaux",
    rating: 4.8,
    reviews: 202,
    price: "€€",
    availability: "Mar. 04",
    image:
      "https://res.cloudinary.com/planity/image/upload/t_d_search,f_auto/uhmmqccxdilgcupljse3",
  },
  {
    id: 3,
    name: "Holy Cut",
    address: "59 Rue du Mirail, 33000 Bordeaux",
    rating: 4.7,
    reviews: 150,
    price: "€",
    availability: "Disponible maintenant",
    image:
      "https://res.cloudinary.com/planity/image/upload/t_d_search,f_auto/uhmmqccxdilgcupljse3",
  },
];
