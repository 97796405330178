import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import BookingDetails from './pages/BookingDetails/BookingDetails';
import ListServices from './pages/ListServices/ListServices';
import Service from './pages/Service/Service';
import Home from './pages/home/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/service-detail/:id" element={<Service />} />
        <Route path="/reserve" element={<ListServices />} />
        <Route path="/reserve-detail/:id" element={<BookingDetails />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
