import Carousel from "react-bootstrap/Carousel";
import image from '../../assets/images/bg-hero.jpg'
import './Slide.css'

function Slide() {
  return (
    <Carousel className="slide-servide">
      <Carousel.Item>
        <img src={image} alt="img" />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={image} alt="img" />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={image} alt="img" />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Slide;
